import { observeElement, getWidget } from '@uvocore-front/utils';

const isPageConfig = window.pageConfig
  && Object.keys(window.pageConfig).length > 0
  && window.pageConfig.constructor === Object;

const getCustomCalcProperty = (property) => {
  // eslint-disable-next-line max-len
  const customProperty = isPageConfig
    && window.pageConfig.calculator !== undefined
    && window.pageConfig.calculator.hasOwnProperty(property)
    ? window.pageConfig.calculator[property]
    : undefined;
  return typeof customProperty === 'object' ? [customProperty[0]] : customProperty;
};

const pageCalculatorConfig = isPageConfig && (window.pageConfig.calculator !== undefined)
  ? window.pageConfig.calculator
  : undefined;

const deadlineDefault = getCustomCalcProperty('deadlineDefault');

const options = {
  deafault: {
    paperTypesDefault: 2,
    deadlinesDefault: deadlineDefault || [108139],
    pagesDefault: 1,
    ...pageCalculatorConfig,
  },
};

if (document.querySelector('[data-calc-mini]')) {
  observeElement({
    element: '[data-calc-mini]',
    marginValue: 20,
    callback: () => {
      getWidget({
        widgetLink: '/assets/js/calc.js',
        loader: false,
        fn: () => {
          let calc;
          if (typeof Calc === 'function') {
            calc = new Calc({
              ID: '2',
              calculatorType: 'DefaultCalculator',
              format: 'json',
              options,
            });
          }
        },
      });
    },
  });
}

if (document.querySelector('[data-calc-custom]')) {
  observeElement({
    element: '[data-calc-custom]',
    marginValue: 20,
    callback: () => {
      getWidget({
        widgetLink: '/assets/js/calc.js',
        loader: false,
        fn: () => {
          let calc;
          if (typeof Calc === 'function') {
            calc = new Calc({
              ID: '2',
              calculatorType: 'DefaultCalculator',
              format: 'json',
              parent: '[data-calc-custom]',
              options,
            });
          }
        },
      });
    },
  });
}

/* eslint-disable */
/**
 *  Video Observer
 */
import { observeElement } from '@uvocore-front/utils';

// Videos Observer
observeElement({
  element: '[data-observed-video]',
  marginValue: 150,
  callback: (video) => {
    const poster = video.getAttribute('data-poster');
    poster && (video.poster = poster);

    for (const source in video.children) {
      const videoSource = video.children[source];

      if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
        const src = videoSource.getAttribute('data-src');
        src && (videoSource.src = src);
      }
    }

    video.load();
  },
});

import { hasParent } from '../utils';

export default function navDropdownInit() {
  const nav = document.querySelector('.nav');

  const handler = e => {
    if (window.innerWidth <= 1215) {
      if (hasParent(e.target, '.nav__item--dropdown')) {
        e.target.parentNode.querySelector('ul').classList.toggle('active');
      }
    }
  }

  nav.addEventListener('click', handler);
}
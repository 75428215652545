/**
 *
 * Filter samples
 *
 */

const samplesFilter = () => {
  // Variables
  const filterButtons = document.querySelectorAll('.samples-filter-item');
  const dropdownItem = document.querySelector('.other-samples .samples-filter-item');
  const dropdown = document.querySelector('.other-samples-dropdown');
  const dropdownSelect = document.querySelector('#other-samples-select');
  const currentSelect = document.querySelector('.samples-filter-current');
  const dropdownClose = document.querySelector('.dropdown-close');
  const samples = document.querySelectorAll('.sample');
  const moreButton = document.querySelector('.show-more-button button');
  const countersArr = [];

// Functions
  window.filterSamples = (elValue, e, type) => {
    const dropDownFilterItems = document.querySelectorAll('.dropdown-filter-item');

    removeClassAll(samples, 'show');
    removeClassAll(dropDownFilterItems, 'active');
    removeClassAll(filterButtons, 'active');
    dropdown.classList.remove('show');

    elValue = !elValue ? dropdownSelect.options[dropdownSelect.selectedIndex].text : elValue;
    type = !type ? dropdownSelect.options[dropdownSelect.selectedIndex].value : type;

    document.querySelector('[data-category-item=' + type + ']').classList.add('active');

    if (e === 'dropdown-filter-item') {
      closeDropdown();
      dropdownItem.classList.add('active');
      dropdownItem.parentNode.classList.add('active');

    } else {
      closeDropdown();
      dropdownItem.classList.remove('active');
      dropdownItem.parentNode.classList.remove('active');

    }

    currentSelect.innerText = elValue;

    if (elValue === 'other examples') {
      dropdownSelect.value = 0;
      document.querySelector('.select-other-samples').classList.remove('active');
    } else {
      dropdownSelect.value = type;
      document.querySelector('.select-other-samples').classList.add('active');
    }

    if (document.querySelector('.sample[data-category=' + type + ']') !== null) {
      document.querySelector('.samples-list').setAttribute('data-type', type);
      addClassAll(document.querySelectorAll('.sample[data-category=' + type + ']'), 'show');
    }
  };

  function sampleCounter() {
    Array.prototype.slice.call(samples).forEach(function (sample) {
      countersArr[sample.getAttribute('data-category')] = {
        'cat-sample-id': document.querySelectorAll('.sample[data-category=' + sample.getAttribute('data-category') + ']').length,
        'cat-name': sample.getAttribute('data-category').replace(/\b\w/g, function (l) {return l.toUpperCase()}).replace(/-/g, ' '),
        'optionalCatName': sample.getAttribute('data-category-name'),
      };
    });

    for (let category in countersArr) {
      const counterSpan = document.createElement('span');
      const dropdownBtn = document.createElement('button');
      const selectOption = document.createElement('option');

      if (category !== 'essay' && category !== 'research-paper') {
        let catName = countersArr[category]['optionalCatName'] ? countersArr[category]['optionalCatName'] : countersArr[category]['cat-name'];

        counterSpan.innerText = ' (' + countersArr[category]['cat-sample-id'] + ')';
        dropdownBtn.innerText = catName;
        dropdownBtn.setAttribute('data-category-item', category);
        dropdownBtn.classList.add('dropdown-filter-item');
        dropdownBtn.setAttribute('onclick', 'filterSamples("' + catName + '","dropdown-filter-item", "' + category + '")');
        dropdownBtn.appendChild(counterSpan);
        dropdown.appendChild(dropdownBtn);

        selectOption.innerText = catName;
        selectOption.value = category;
        dropdownSelect.appendChild(selectOption);
        dropdownSelect.setAttribute('onchange', 'filterSamples(false,"dropdown-filter-item",false)');
      }
    }
  }

  function removeClassAll(arr, className) {
    Array.prototype.slice.call(arr).forEach(function (e) {
      e.classList.remove(className);
    });
  }

  function addClassAll(arr, className, more) {
    let index = 0;
    Array.prototype.slice.call(arr).forEach(function (e) {
      if (index <= 2 && arr.length > 3) {
        document.querySelector('.show-more-button').classList.add('show');
        arr[index].classList.add(className);
      } else if (arr.length <= 3 || arr.length <= more) {
        e.classList.add(className);
        document.querySelector('.show-more-button').classList.remove('show');
      }

      if (index > 2 && index <= more) {
        arr[index].classList.add(className);
      }

      ++index;
    });
  }

  function openDropdown() {
    dropdown.classList.add('show');
    dropdownItem.classList.add('show');
  }

  function closeDropdown() {
    dropdown.classList.remove('show');
    dropdownItem.classList.remove('show');
  }

// Events
  if (dropdownItem && dropdownClose && moreButton) {
    dropdownItem.addEventListener('click', function () {
      if (dropdown.classList.contains('show')) {
        closeDropdown();
      } else {
        openDropdown();
      }
    });

    dropdownClose.addEventListener('click', function () {
      closeDropdown();
    });

    moreButton.addEventListener('click', function () {
      const type = document.querySelector('.samples-list').getAttribute('data-type');
      const moreValue = document.querySelectorAll('.sample.show').length;
      addClassAll(document.querySelectorAll('.sample[data-category=' + type + ']'), 'show', moreValue + 2);
    });

    // Init
    sampleCounter();
    addClassAll(document.querySelectorAll('.sample[data-category="essay"]'), 'show');
    document.querySelector('.samples-filter-item[data-category-item="essay"]').classList.add('active');
  }
};

export default samplesFilter;

import { observeElement, getWidget } from '@uvocore-front/utils';
import { formatDate } from '../utils';
import { tns } from 'tiny-slider/src/tiny-slider';


const renderLCP = (orders) => {
  const htmlTemplate = function (item) {
    return (
      [
        '<div class="last-orders-item last-orders-slider__item">',
        '<div class="last-orders-item__inner">',
        '<div class="last-orders-item__title">' + item.topicTitle + '</div>',
        '<div class="last-orders-item__content">',
        '<table class="last-orders-item__properties">',
        '<tr>',
        '<td>Discipline</td>',
        '<td>' + item.discipline + '</td>',
        '</tr>',
        '<tr>',
        '<td>Academic level</td>',
        '<td>' + item.academicLevel + '</td>',
        '</tr>',
        '<tr>',
        '<td>Pages</td>',
        '<td>' + item.pages + '</td>',
        '</tr>',
        '<tr>',
        '<td>Delivered</td>',
        '<td>' + formatDate(item.delivered) + '</td>',
        '</tr>',
        '<tr>',
        '<td>Price</td>',
        '<td>' + '$' + item.orderPrice + '</td>',
        '</tr>',
        '</table>',
        '</div>',
        '</div>',
        '</div>'
      ].join(''));
  }

  orders = orders.map(function (item) {
    return htmlTemplate(item);
  });

  document.querySelector('.last-orders-slider').innerHTML = orders.join('');
}


if (document.querySelector('.last-orders-slider')) {
  observeElement({
    element: '.last-orders-slider',
    marginValue: 400,
    callback: () => {
      getWidget({
        widgetLink: '/assets/js/lcp.js',
        loader: false,
        fn: () => {

          new LCP({
            ID: '2',
            format: 'json',
            template: 'div',
            limit: 10,
            fields: ['paperType', 'topicTitle', 'discipline', 'academicLevel', 'pages', 'delivered', 'orderPrice', ],
            callback: function() {

              const data = this.options.json;
              renderLCP(data);

              const LCPSlider = tns({
                'container': '.last-orders-slider',
                'slideBy': 1,
                'autoplayHoverPause': true,
                'mouseDrag': true,
                'autoplay': false,
                'autoplayButtonOutput': false,
                'loop': false,
                'responsive': {
                  0: {
                    'controls': false,
                    'gutter': 0,
                    'items': 1,
                    'nav': true
                  },
                  1000: {
                    'items': 2,
                    'gutter': 10
                  },
                  1001: {
                    'controls': true,
                    'items': 3
                  }
                }
              });
            }
          });

        },
      });
    },
  });
}

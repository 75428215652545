import { observeElement, getWidget } from '@uvocore-front/utils';
import { tns } from 'tiny-slider/src/tiny-slider';

observeElement({
  element: '.chart-slider__container',
  marginValue: 400,
  callback: () => {
    getWidget({
      widgetLink: '/assets/js/lcp.js',
      loader: false,
      fn: () => {
        const slider = tns({
          container: '.chart-slider',
          loop: false,
          items: 2,
          gutter: 10,
          slideBy: 'page',
          autoplay: false,
          mouseDrag: true,
          lazyload: true,
          nav: true,
          responsive: {
            768: {
              nav: false,
              items: 4,
              mouseDrag: false,
            },
          },
        });
      },
    });
  },
});

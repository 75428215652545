// Mobile navigration init

export default function mobileNavigationInit(){
  const button = document.querySelector('.js-hamburger');

  if (!button) return false;

  const menuBlock = document.querySelector('.header__nav');
  const menu = menuBlock.querySelector('ul');

  if (getComputedStyle(menuBlock).visibility === 'hidden') {
    menuBlock.setAttribute('aria-hidden', true);
  }

  const toggle = () => {
    button.classList.toggle('is-active');
    menuBlock.classList.toggle('is-active');

    document.documentElement.classList.toggle('no-scroll');
    document.body.classList.toggle('no-scroll');

    if (menuBlock.classList.contains('is-active')) {
      menuBlock.setAttribute('aria-hidden', false);
      button.setAttribute('aria-expanded', true);

      menuBlock.style.height = `${window.innerHeight}px`;

      menuBlock.addEventListener('click', menuOnClickHandler);
    } else {
      menuBlock.setAttribute('aria-hidden', true);
      button.setAttribute('aria-expanded', false);

      menuBlock.style.height = '';
      menuBlock.removeEventListener('click', menuOnClickHandler);
    }
  }

  const menuOnClickHandler = (e) => e.target === menuBlock && toggle();

  button.addEventListener('click', toggle);
}

window.addEventListener('load', () => {
  if (document.querySelector('.simple-page--testimonials')) {
    const loader = document.querySelector('.Testimonials__loader');
    const showMoreBtn = document.querySelector('.Testimonials__show-more-btn');

    const getTestimonialsData = limit => fetch('/assets/testimonials/list.json?siteId=2&limit=' + limit).then(response => response.json()).catch(err => new Error(err));

    const testimonialsList = data => {
      const formatDate = tmDate => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let formatedDate = new Date(tmDate * 1000);
        return `${formatedDate.getDate()} ${monthNames[formatedDate.getMonth()]} ${formatedDate.getFullYear()}`;
      };

      let template = item => `<div class="Testimonial">
      <strong class="Testimonial__title">${item.orderTopic}</strong>
        <p class="Testimonial__text">${item.body}</p>
        <div class="Testimonial__footer">
          <div class="Testimonial__footer-order">Order<strong>#${item.customerId}</strong></div>
          <div class="Testimonial__footer-pages">Pages<strong>${item.orderPages}</strong></div>
          <div class="Testimonial__footer-date">Date<strong>${formatDate(item.createdAt)}</strong></div>
          <div class="Testimonial__footer-discipline">Discipline<strong>${item.orderDiscipline}</strong></div>
        </div></div>`;

      return data.map(i => template(i)).join('');
    };

    const appendHtml = data => {
      let tabContainer = document.querySelector('[data-tab-container="testimonials"]');
      let container = tabContainer.querySelector('.Testimonials__tabs-content');

      let toDom = str => {
        let tmp = document.createElement("div");
        tmp.innerHTML = str;
        return tmp.childNodes;
      };

      Array.prototype.slice.call(toDom(testimonialsList(data))).forEach(i => container.appendChild(i));
    };

    getTestimonialsData(10).then(data => {
      loader.classList.remove('active');
      showMoreBtn.classList.add('show');
      appendHtml(data.data.testimonials);
    });

    const removeClasses = (arr, className) => {
      [].slice.call(arr).forEach(function (i) {
        i.classList.remove(className);
      });
    };

    window.getTab = (el, tab) => {
      let tabContainer = document.querySelector('.Testimonials__tabs-container[data-tab-container=' + tab + ']');
      removeClasses(document.querySelectorAll('.Testimonials__tabs-tab'), 'active');
      removeClasses(document.querySelectorAll('.Testimonials__tabs-container'), 'active');
      tabContainer.classList.add('active');
      el.classList.add('active');
    };

    window.getMore = perPage => {
      let tmCount = document.querySelectorAll('.Testimonial').length;
      loader.classList.add('active');
      showMoreBtn.setAttribute('disabled', true);

      getTestimonialsData(tmCount + perPage).then(data => {
        let moreTms = data.data.testimonials.slice(tmCount);
        loader.classList.remove('active');
        showMoreBtn.removeAttribute('disabled');
        appendHtml(moreTms);
      });
    };
  }
});

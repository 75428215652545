import { observeElement, loadScripts } from '@uvocore-front/utils';

import { tns } from 'tiny-slider/src/tiny-slider';
import { ReviewCarousel } from '../../components/SiteJabber';
import stickyHeaderInit from './components/sticky-header';
import mobileNavigationInit from './components/mobile-nav';
import navDropdownInit from './components/dropdowns';
import Tabs from './components/tabs';
import Accordion from './components/Accordion';
import SamplePDF from './components/SamplesIframe';
import samplesFilter from './samplesFilter';
import '../../components/ImagesLazyLoading';
import '../../components/VideoLazyLoading';
import '../../components/NotifyWidget';
import '../../components/PayInTwoNotify';
import './components/Chart';
import './components/calculator';
import './components/lcp';
import './components/common-pages';
import './components/testimonials-page';
import './components/SamplesSlider';

// testimonials slider init
if (document.querySelector('.testimonials-slider')) {
  ReviewCarousel();

  observeElement({
    element: '.testimonials-slider',
    marginValue: 400,
    callback: () => {
      // eslint-disable-next-line no-unused-vars
      const testimonialsSlider = tns({
        container: '.testimonials-slider',
        slideBy: 1,
        autoplayHoverPause: true,
        mouseDrag: true,
        autoplay: false,
        autoplayButtonOutput: false,
        loop: false,
        responsive: {
          0: {
            controls: false,
            gutter: 0,
            items: 1,
            nav: true,
          },
          1000: {
            items: 2,
            gutter: 30,
            controls: true,
          },
          1170: {
            items: 3,
            gutter: 30,
          },
        },
      });
    },
  });
}

if (document.querySelector('.accordion')) {
  observeElement({
    element: '.accordion',
    marginValue: 400,
    callback: () => {
      Accordion();
    },
  });
}

if (document.querySelector('.testimonials-section .tabs')) {
  observeElement({
    element: '.testimonials-section .tabs',
    marginValue: 300,
    callback: () => {
      new Tabs(document.querySelector('.testimonials-section .tabs'), 0);
    },
  });
}

if (document.querySelector('.samples-section')) {
  observeElement({
    element: '.samples-section__main',
    marginValue: 300,
    callback: () => {
      SamplePDF();
    },
  });
}

if (document.querySelector('.samples-page')) {
  observeElement({
    element: '.samples-list',
    marginValue: 300,
    callback: () => {
      samplesFilter();
      SamplePDF({
        sampleSection: document.querySelector('.samples-list'),
        sampleButton: '.sample-button',
      });
    },
  });
}

window.addEventListener('load', () => {
  stickyHeaderInit();
  mobileNavigationInit();

  if (document.querySelector('.nav')) {
    navDropdownInit();
  }

  // Add attribute to facebook messenger btn
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (!isMobile) {
    const fbChatBtns = document.querySelectorAll('[data-messenger]');

    [...fbChatBtns].forEach((btn) => {
      btn.setAttribute('target', '_blank');
    });
  }

  if (isMobile) document.documentElement.classList.add('is-touch-device');
});

export default function Tabs(selector, startPaneIndex) {
  var startPaneIndex = startPaneIndex || 0,
    tabSelectors = selector.querySelectorAll('.tabs__selector-item'),
    tabSelectorsContainer = tabSelectors[0].parentNode,
    tabPanes = selector.querySelectorAll('.tabs__tab-pane');

  var state = {
    currentTabPane: null,
    quantity: tabSelectors.length
  }

  for (var i = 0; i < state.quantity; i++) {
    tabSelectors[i].setAttribute('data-tab-selector-id', i);
    tabPanes[i].setAttribute('data-tab-pane-id', i);
  }

  this.changeTabPane = function (index) {
    if (state.currentTabPane !== null) {
      tabSelectors[state.currentTabPane].classList.remove('tabs__selector-item--is-active');
      tabSelectors[state.currentTabPane].setAttribute('aria-selected', false);

      tabPanes[state.currentTabPane].classList.remove('tabs__tab-pane--is-active');
      tabPanes[state.currentTabPane].setAttribute('aria-hidden', true);
    }

    state.currentTabPane = index;
    tabSelectors[state.currentTabPane].classList.add('tabs__selector-item--is-active');
    tabSelectors[state.currentTabPane].setAttribute('aria-selected', true);

    tabPanes[state.currentTabPane].classList.add('tabs__tab-pane--is-active');
    tabPanes[state.currentTabPane].setAttribute('aria-hidden', false);
  }

  var hasInParents = function (node, attrs) {
    if (node.matches('.tabs__selector-item') || node.id === attrs) return node;
    if (node.parentNode) return hasInParents(node.parentNode, attrs);
    return false;
  }

  var selectorClickHandler = function (e) {
    var target = e.target;

    if (target !== tabSelectorsContainer) {
      if (!target.matches('.tabs__selector-item')) {
        if (hasInParents(target, '.tabs__selector-item')) {
          target = hasInParents(target, '.tabs__selector-item');
        } else {
          target = null
        }
      }

      if (target && !target.classList.contains('tabs__selector-item--is-active')) {
        this.changeTabPane(target.getAttribute('data-tab-selector-id'));
      }
    }

  }

  this.changeTabPane(startPaneIndex);
  tabSelectorsContainer.addEventListener('click', selectorClickHandler.bind(this));
}
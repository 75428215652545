// common pages
import { observeElement } from '@uvocore-front/utils';
import Accordion from './Accordion';

const commonPage = document.getElementById('CommonPage');
if (commonPage) {
  observeElement({
    element: '[data-site-id]',
    marginValue: 100,
    callback: () => {

      function init() {
        const pageName = commonPage.getAttribute('data-page');
        const siteId = commonPage.getAttribute('data-site-id');
        const policies = commonPage.getAttribute('data-policies');
        const policyId = commonPage.getAttribute('data-policy-id');

        let widgetOptions = {
          siteId,
          pageName,
        };

        if (policies && policyId) {
          widgetOptions = {
            siteId, pageName, policies, policyId,
          };
        }

        window.commonPagesWidget.widget(commonPage, widgetOptions);
      }

      // eslint-disable-next-line consistent-return
      function toggleListAccordionInit() {
        if (!document.querySelector('.toggle-list')) return false;

        if (!document.querySelector('.toggle-list .calculate-height')) {
          [...document.querySelectorAll('.toggle-list .toggle-content')].forEach((i) => {
            i.innerHTML = `<div class='calculate-height'>${i.innerHTML}</div>`;
          });
        }

        Accordion({
          faqContainer: document.querySelector('.toggle-list'),
          headingSelector: '.toggle-link',
          contentSelector: '.calculate-height',
          faqItemsSelector: '.toggle-list > li',
          contentWrapperSelector: '.toggle-content',
        });
      }

      if (typeof window.commonPagesWidget !== 'undefined') {
        init();
      } else {
        const script = document.createElement('script');
        script.src = '/assets/js/commonPagesWidget.min.js';
        script.async = true;
        script.onload = function () {
          init();
        };
        document.getElementsByTagName('body')[0].appendChild(script);
      }

      window.addEventListener('contentRendered', () => {
        toggleListAccordionInit();
      });
    },
  });
}

observeElement({
  element: '[data-site-hardcode]',
  marginValue: 100,
  callback: () => {
    // Toggle List Accordion
    function toggleListAccordionInit() {
      if (!document.querySelector('.toggle-list')) return false;

      if (!document.querySelector('.toggle-list .calculate-height')) {
        [...document.querySelectorAll('.toggle-list .toggle-content')].forEach(i => {
          i.innerHTML = "<div class='calculate-height'>" + i.innerHTML + "</div>";
        });
      }

      Accordion({
        faqContainer: document.querySelector('.toggle-list'),
        headingSelector: '.toggle-link',
        contentSelector: '.calculate-height',
        faqItemsSelector: '.toggle-list > li',
        contentWrapperSelector: '.toggle-content'
      });
    }

    toggleListAccordionInit();
  },
});

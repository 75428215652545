// Sticky header init
export default function stickyHeaderInit() {
  function headerSticky() {
    if (document.querySelector('.order-page')) return false;

    const header = document.querySelector('.header__content');

    const onScrollHandler = function (e) {
      if (window.innerWidth > 1215) {
        if (window.scrollY > 250) {
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
        }
      }
    };

    window.addEventListener('scroll', onScrollHandler);
  }

  headerSticky();
}

const mobileMediaQuery = window.matchMedia('(max-width: 768px)');

function handleTooltipBehavior(e) {
  let cleanupFunction = () => {};

  if (!e.matches) {
    return cleanupFunction;
  }

  const tooltipTrigger = document.querySelector('.payInTwoNotify__tooltip__title');
  const tooltip = document.querySelector('.payInTwoNotify__tooltip__text');

  if (!tooltipTrigger || !tooltip) {
    return cleanupFunction;
  }

  const handleTooltipShow = () => tooltip.classList.add('show');

  tooltipTrigger.addEventListener('touchstart', handleTooltipShow, { passive: true });

  const handleDocumentTouch = (event) => {
    const clickedOutside = !tooltipTrigger.contains(event.target)
      && !tooltip.contains(event.target);
    if (clickedOutside) {
      tooltip.classList.remove('show');
    }
  };

  document.addEventListener('touchstart', handleDocumentTouch, { passive: true });

  cleanupFunction = () => {
    tooltipTrigger.removeEventListener('touchstart', handleTooltipShow);
    document.removeEventListener('touchstart', handleDocumentTouch);
  };

  return cleanupFunction;
}

let cleanup = handleTooltipBehavior(mobileMediaQuery);

mobileMediaQuery.addEventListener('change', (event) => {
  cleanup();
  cleanup = handleTooltipBehavior(event);
});

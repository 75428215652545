import { observeElement, getWidget } from '@uvocore-front/utils';

export default () =>
  observeElement({
    element: '#reviewCarouselWidget',
    marginValue: 300,
    callback: () => {
      getWidget({
        widgetLink: '//static.sitejabber.com/js/widget.min.1610825327.js',
        loader: false,
        fn: () => {
          new STJR.ReviewCarouselWidget({ /* eslint-disable-line */
            id: 'reviewCarouselWidget',
            url: 'affordablepapers.com',
          }).render();
        },
      });
    },
  });

import { observeElement } from '@uvocore-front/utils';
import { tns } from 'tiny-slider/src/tiny-slider';

observeElement({
  element: '[data-samples-slider]',
  marginValue: 400,
  callback: () => {
    tns({
      container: '[data-samples-slider]',
      loop: true,
      items: 1,
      gutter: 10,
      slideBy: 'page',
      autoplay: false,
      mouseDrag: true,
      lazyload: true,
      nav: true,
      controls: false,
      responsive: {
        768: {
          items: 3,
          controls: true,
        },
      },
    });
  },
});

/**
 *
 *  SamplePDF component
 *
 * @param options
 * @constructor
 *
 */

const SamplePDF = (options = {
  sampleSection: document.querySelector('.samples-section__main'),
  sampleButton: '.sample-item__properties + .sample-item__btn',
  pdfContainer: document.querySelector('.PdfContainer'),
}) => {

  const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  const samplePdfContainer = `
  <div class="PdfContainer">
    <div class="PdfContainer__overlay"></div>
    <button type="button" class="PdfContainer__close" title="Close">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 24 24">
        <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
      </svg>
    </button>
  </div>
  `;

  if (options.sampleSection) {
    options.sampleSection.insertAdjacentHTML('beforeEnd', samplePdfContainer);
  }

  if (document.querySelector('.PdfContainer') && !iOS) {
    const pdfContainer = document.querySelector('.PdfContainer');
    const iframe = document.createElement('iframe');

    const closePdfContainer = () => {
      pdfContainer.classList.remove('show');
      pdfContainer.removeChild(iframe)
    };

    const showSample = el => {
      iframe.setAttribute('src', el.href);
      iframe.setAttribute('width', '100%');
      iframe.setAttribute('height', '100%');
      iframe.setAttribute('frameborder', '0');
      pdfContainer.appendChild(iframe);
      pdfContainer.classList.add('show');
    };

    Array.prototype.slice.call(document.querySelectorAll(options.sampleButton)).forEach(el => {
      el.addEventListener('click', function (e) {
        e.preventDefault();
        showSample(el);
      })
    });

    document.querySelector('.PdfContainer__close').addEventListener('click', () => {
      closePdfContainer()
    });

    document.querySelector('.PdfContainer__overlay').addEventListener('click', () => {
      closePdfContainer()
    });
  }
};

export default SamplePDF;

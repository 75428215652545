export function hasParent(el, parent, stopEl) {
  if (el.parentNode === document.documentElement || el.parentNode.matches(stopEl)) return false;

  if (el.parentNode.matches(parent)) {
    return true;
  } else {
    hasParent(el.parentNode, parent);
  }
};


export function formatDate(unformattedDate) {
  var deliveredDate = new Date,
    today = new Date,
    MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

  deliveredDate.setTime(Date.parse(unformattedDate));
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate()).valueOf();

  if (deliveredDate < today - MILLISECONDS_IN_ONE_DAY) {
    return dateToYMD(deliveredDate);
  } else if (deliveredDate < today) {
    return 'Yesterday';
  } else {
    return 'Today';
  }
}


function dateToYMD(date) {
  const d = new Date(date);
  const year = d.getFullYear();

  let month = `${(d.getMonth() + 1)}`;
  let day = `${d.getDate()}`;


  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function is_touch_device() {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function (query) {
    return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${`${name}`.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
